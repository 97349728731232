import styles from './ic-close-2.module.scss';

/* eslint-disable-next-line */
export interface IcClose2Props {
  size: number;
  color: string;
}

export function IcClose2(props: IcClose2Props) {
  return (
    <svg className={styles['container']} width={props.size} height={props.size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill={props.color} />
      <path d="M9.00006 15L15 9" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.9999 15L9 9" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

IcClose2.defaultProps = {
  size: 24,
  color: 'red',
}

export default IcClose2;
