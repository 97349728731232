// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import styles from './app.module.scss';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import ReactPixel from 'react-facebook-pixel';
import { NX_INTERCOM_ID, NX_PIXEL_ID } from './config';
import { IcLoading2 } from '@clc-v2/icons';
const Auth = lazy(() => import('./pages/Auth'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));

axios.defaults.headers.common['X-Timezone-Offset'] = new Date().getTimezoneOffset().toString();

const AuthPath = process.env.NX_AUTH_REDIRECT ?? ''

export function App() {
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactPixel.init(NX_PIXEL_ID, undefined, {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false // enable logs
      });
    }
  }, []);
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactPixel.pageView();
    }
  }, [location.pathname]);
  return (
    <div className="font-inter">
      <IntercomProvider
        appId={NX_INTERCOM_ID}
        autoBoot
      >
        <Routes>
          <Route
            path={AuthPath}
            element={
              <Suspense fallback={<div className="w-screen h-screen flex justify-center items-center"><IcLoading2 size={36} color="white" /></div>}>
                <Auth />
              </Suspense>
            }
          />
          <Route
            path='verify-email'
            element={
              <Suspense fallback={<div style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}><IcLoading2 size={36} color="white" /></div>}>
                <VerifyEmail />
              </Suspense>
            }
          />
          <Route path='*' element={<Navigate to={AuthPath + (window.location.search ? (window.location.search + '&') : '?') + 'tab=login'} />} />
        </Routes>
      </IntercomProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default App;
