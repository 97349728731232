import styles from './ic-loading-2.module.scss';

/* eslint-disable-next-line */
export interface IcLoading2Props {
  size: number;
  color: string;
}

export function IcLoading2(props: IcLoading2Props) {
  return (
    <svg className={styles['container']} xmlns="http://www.w3.org/2000/svg" width={props.size} height={props.size} viewBox="0 0 24 24">
      <circle cx="18" cy="12" r="0" fill={props.color}>
        <animate attributeName="r" begin=".67" calcMode="spline" dur="1.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0" />
      </circle>
      <circle cx="12" cy="12" r="0" fill={props.color}>
        <animate attributeName="r" begin=".33" calcMode="spline" dur="1.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0" />
      </circle>
      <circle cx="6" cy="12" r="0" fill={props.color}>
        <animate attributeName="r" begin="0" calcMode="spline" dur="1.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0" />
      </circle>
    </svg>
  );
}

IcLoading2.defaultProps = {
  size: 24,
  color: '#fe718d',
}

export default IcLoading2;
